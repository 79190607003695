import React, { useState, useEffect } from "react";
import RequestService from "../api/RequestService";
import EventList from "../components/UI/Event/EventList";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { copy, isEmpty } from "../utils/service";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import moment from "moment";
import { formatNumber, getQueryParam } from "../utils/service";
import { useRef } from "react";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import { useSearchParamsArray } from "../hooks/useSearchParamsArray";
import EventsFlow from "./EventsFlow";
import noImage from "../images/no-image.png";
import { Autocomplete, Button, Icon } from "react-materialize";
import M from "materialize-css";

const Events = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState({});
  const [ymapState, setYmapState] = useState({
    center: [0, 0], //[55.755864, 37.617698],
    zoom: 12, // 3,
    // controls: ["zoomControl"],
  });
  const [placemarks, setPlacemarks] = useState([]);
  const [eventPage, setEventPage] = useState(1);
  // const lastEventElement = useRef();
  const searchParamsArray = useSearchParamsArray();
  const [cityTitle, setCityTitle] = useState("Город проведения");
  const [locationOptions, setLocationOptions] = useState({ test: null });
  const [eventLocation, setEventLocation] = useState("");
  const [searchCityCard, setSearchCityCard] = useState(true);

  const createEventGroups = (data) => {
    let eventGroups = [];
    let addresses = [...new Set(data.map((v) => normalizeAddress(v.address)))];
    // console.log(addresses);

    for (let i = 0; i < addresses.length; i++) {
      let group = { address: addresses[i], events: [] };

      for (let j = 0; j < data.length; j++) {
        data[j].participation_packages = data[j].participation_packages.filter(
          (p) => !p.deleted_at && !p.is_expired
        );

        if (
          normalizeAddress(data[j].address) == addresses[i] &&
          data[j].participation_packages.length > 0 &&
          data[j].format != 2
        ) {
          group.events.push(data[j]);
          group.latitude = data[j].latitude;
          group.longitude = data[j].longitude;
          group.group_id = data[j].id;
        }
      }

      // сортировка событий
      group.events = group.events.sort(
        (a, b) =>
          new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
      );
      eventGroups.push(group);
    }

    eventGroups = eventGroups.filter(
      (eg) => eg.latitude && eg.longitude && eg.group_id && eg.events.length > 0
    );

    return eventGroups;
  };

  const createBalloonContentBody = (elements) => {
    // elements = [event1, event2, event3, ...]
    let content = "";
    elements.forEach((element) => {
      let pp = null;
      let minPrice = null;
      // console.log(element.participation_packages);

      if (element.participation_packages.length > 0) {
        pp = element.participation_packages.sort(
          (a, b) => a.price - b.price
        )[0];
        minPrice = pp ? pp.price : null;
      }

      // console.log(minPrice + element.name);
      // console.log(element);

      content += element.name
        ? "<div><img src='" +
          (element.cover_img_url ? element.cover_img_url : noImage) +
          "' style='max-height:200px; width:100%; object-fit:contain; margin-top: 10px; margin-bottom: 10px; border-radius: 10px;' /></div>" +
          (element.name
            ? "<a href='/events/" +
              element.id +
              "' class='iq-link m500'>" +
              element.name +
              "</a><br>" +
              "<div class='tertiary-color s300'>от " +
              formatNumber(minPrice) +
              " " +
              (pp.currency_symbol ? pp.currency_symbol : pp.currency_code) +
              ", подробности ниже ⬇</div>"
            : "Без названия") +
          "<div><i class='material-icons hand-cursor accent-color' style='left: 22px; margin-top: 10px; font-size: 17px;' onclick='_copyText(`" +
          document.location.origin +
          "/events/" +
          element.id +
          "`)'>ios_share</i>" +
          "<i class='material-icons-round hand-cursor accent-color' style='position: relative; color: var(--text-tertiary-color); margin-left: 12px; top: 2.5px; font-size: 20px;' onclick='_addBookmark(`" +
          element.id +
          "`)'>bookmark_border</i></div>" +
          "<div class='secondary-color' style='font-weight: 700; margin-top: 10px;'>Дата и время: " +
          "<span class='tertiary-color' style='font-weight: 300;'>" +
          moment(element.datetime).format("DD.MM.YYYY HH:mm") +
          "</span>" +
          "</div>" +
          "<div class='secondary-color' style='font-weight: 700;'>Место: " +
          "<span style='font-weight: 300;' class='tertiary-color'>" +
          (element.location ? element.location : "Город неизвестен") +
          ", " +
          (element.address
            ? "ул. " + element.address
            : "Адрес неизвестен, смотрите координаты") +
          (element.place ? " (" + element.place + ")" : "") +
          "</span>" +
          "</div>" +
          "<div class='secondary-color' style='font-weight: 700; margin-bottom: 15px;'>Описание (кратко): " +
          "<span style='font-weight: 300;' class='tertiary-color'>" +
          (element.description
            ? element.description.substring(0, 250) + "..."
            : "—") +
          "</span>" +
          "</div><div class='divider mb15'></div>"
        : "Нет подробной информации о месте проведения" + "</span>";
    });

    return content;
  };

  const getEvents = async (coords = null) => {
    // if (events && page > events.total_pages) return;
    let response = (await RequestService.getAllEvents()).data; // getAllEvents(coords))
    if (!response.response_code) {
      console.error(response);
      return;
    }
    let allEventsData = response.result;
    // console.log(allEventsData);
    setEvents(allEventsData);

    // сейчас массив объектов, а должен быть массив (групп), в которых n-объектов событий
    // [ { group_id: id для react, icon_caption: название на иконке, latitude: X, longitude: Y, address: адрес, events: [1,2,3] }, ... ]
    // balloonContentBody должен генериться в отдельной функции
    // группы создаются по идентичному адресу проведения
    let eventGroups = createEventGroups(allEventsData);
    // console.log(eventGroups);

    let newPlacemarks = [];
    eventGroups.forEach((group) => {
      newPlacemarks.push(
        <Placemark
          modules={["geoObject.addon.balloon"]}
          geometry={[group.latitude, group.longitude]}
          properties={{
            balloonContentHeader: `<span style='position: absolute; color: var(--text-secondary-color); left: 13px; top: -14px; font-size: 17px;'>Событий здесь: ${group.events.length}</span>`,
            balloonContentBody: createBalloonContentBody(group.events),
            balloonContentFooter: null,
            iconCaption: "Событий: " + group.events.length,
          }}
          options={{
            preset: "islands#dotIcon",
            balloonMaxWidth: 280,
            balloonMaxHeight: 280,
            // balloonLayout: layout,
            iconColor: "#3d50fa",
          }}
          key={group.group_id}
        />
      );
    });
    setPlacemarks(newPlacemarks);
  };

  const onInputCity = async (e) => {
    // console.log(e.target.value);
    setEventLocation(e.target.value);
    setCityTitle("Поиск...");
    const response = (await RequestService.searchLocation(e.target.value)).data;
    // console.log(response);
    setCityTitle("Город проведения");
    if (!response.response_code) {
      return;
    }

    let list = {};
    if (response.result.length > 0) {
      response.result.forEach((place) => {
        list[
          place.name_ru // + " (" + place.region_ru + ", " + place.country_ru + ")"
        ] = null;
      });
    }
    setLocationOptions(list);
    // console.log(list);
  };

  useEffect(() => {
    if (document.getElementById("filter-location")) {
      let cityInputInstance = M.Autocomplete.getInstance(
        document.getElementById("filter-location")
      );

      setTimeout(() => {
        cityInputInstance.open();
      }, 300);
    }
  }, [locationOptions]);

  const getGeolocation = async () => {
    let response = await RequestService.getGeolocation();
    // console.log(response);
    // console.log(searchParamsArray);
    if (searchParamsArray.object.lat && searchParamsArray.object.lon) {
      let manualCoords = [
        Number(searchParamsArray.object.lat),
        Number(searchParamsArray.object.lon) - 0.02,
      ];
      setYmapState({
        ...ymapState,
        center: manualCoords,
      });
      return manualCoords;
    }

    if (
      !response ||
      (searchParamsArray.object.lat && searchParamsArray.object.lon)
    )
      return;
    setYmapState({ ...ymapState, center: response });
    return response;
  };

  const setQueryFilters = async () => {
    const response = (await RequestService.searchLocation(eventLocation)).data;
    // console.log(response);

    if (response.result[0]) {
      let urlSearchParams = new URLSearchParams();
      urlSearchParams.append(
        "lat",
        response.result[0].latitude.replace(/0*$/, "")
      );
      urlSearchParams.append(
        "lon",
        response.result[0].longitude.replace(/0*$/, "")
      );

      navigate("?" + urlSearchParams.toString());
      setYmapState({
        zoom: 12,
        center: [
          response.result[0].latitude,
          response.result[0].longitude - 0.02,
        ],
      });
    }
  };

  // useEffect(() => {
  //   setEventsPage();
  // }, []);

  useEffect(() => {
    // console.log(eventPage);
    fetchEvents(eventPage);
  }, [eventPage]);

  useEffect(() => {
    // if (events.hasOwnProperty("elements")) {
    //   events.elements.map((elem) => console.log(elem));
    // }
    // console.log(events);
  }, [events]);

  function normalizeAddress(address) {
    return address
      .toLowerCase() // Приводим к нижнему регистру
      .replace(/ё/g, "е") // Убираем разницу между "ё" и "е"
      .replace(/[^a-zа-я0-9]/gi, " ") // Заменяем знаки препинания на пробелы
      .replace(/(\d+)\s*([а-яa-z])/g, "$1$2") // Убираем пробелы между числом и буквой
      .replace(/(^|\s)(ул|ул\.|улица)($|\s)/g, " ") // Убираем вариации "улица"
      .replace(/\s+/g, " ") // Убираем лишние пробелы
      .trim();
  }

  function areAddressesEqual(addr1, addr2) {
    return normalizeAddress(addr1) === normalizeAddress(addr2);
  }

  function addressTest() {
    const address1 = "проспект Ленина 24А";
    const address2 = "проспект Ленина 24 а";

    const address3 = "радищева 25";
    const address4 = "ул радищева 25";
    const address5 = "улица радищева 25";
    const address6 = "ул. радищева 25";

    console.log(normalizeAddress(address1));
    console.log(normalizeAddress(address2));
    console.log(normalizeAddress(address3));
    console.log(normalizeAddress(address4));
    console.log(normalizeAddress(address5));
    console.log(normalizeAddress(address6));
    console.log(areAddressesEqual(address3, address4));
    // проезды
    // набережные
  }

  const setMapState = (newMapState) => {
    // console.log(newMapState);
    setYmapState(newMapState);
  };

  const setEventsPage = async () => {
    const coords = await getGeolocation();
    getEvents(coords);
  };

  const [fetchEvents, isEventsLoading, eventsError] =
    useFetching(setEventsPage);

  const toggleSearchCityCard = () => {
    setSearchCityCard(!searchCityCard);
  };

  // useObserver(
  //   lastEventElement,
  //   events && eventPage < events.total_pages,
  //   isEventsLoading,
  //   () => {
  //     console.log('top');
  //     setEventPage(eventPage + 1);
  //   }
  // );

  // console.log(1);

  return (
    <div>
      <div className="row nm">
        <div className="col s12 l12" style={{ padding: 0 }}>
          {ymapState.center[0] != 0 && ymapState.center[1] != 0 ? (
            <YMaps version={"2.1.79"}>
              <Map
                defaultState={{
                  center: [55.45, 37.36],
                  zoom: 12,
                  controls: ["zoomControl"],
                }}
                state={ymapState}
                width={"100%"}
                height={window.innerHeight}
                modules={["control.ZoomControl"]}
                onLoad={(ymaps) => {
                  // console.log(ymaps);
                }}
                instanceRef={(mapInstance) => {
                  // console.log(mapInstance);
                }}
                onClick={(e) => {
                  // props.set(e._sourceEvent.originalEvent.coords);
                  // console.log(e._sourceEvent.originalEvent.coords);
                  let createNewPM = (
                    <Placemark
                      modules={["geoObject.addon.balloon"]}
                      geometry={e._sourceEvent.originalEvent.coords}
                      properties={{
                        balloonContentHeader:
                          "<span class='secondary-color'>Новое событие</span>",
                        balloonContentBody:
                          "<div class='secondary-color'>Вы выбрали на карте точку с координатами [" +
                          e._sourceEvent.originalEvent.coords[0] +
                          ", " +
                          e._sourceEvent.originalEvent.coords[1] +
                          "]. Создадим здесь <span style='font-weight: 700;'>новое событие</span>?</div>" +
                          "<div class='mt15'><a href='/events/create?lat=" +
                          e._sourceEvent.originalEvent.coords[0] +
                          "&lon=" +
                          e._sourceEvent.originalEvent.coords[1] +
                          "' class='btn btn-flat iq-btn col s12'>Создать событие</a></div>",
                        iconContent: "Нажмите, чтобы создать событие здесь",
                      }}
                      options={{
                        preset: "islands#darkGreenStretchyIcon",
                        balloonMaxWidth: 280,
                        balloonMaxHeight: 280,
                        // iconColor: "#ff0c0c",
                      }}
                      key={"new-" + (Math.random() * 100000000).toFixed(0)}
                    />
                  );
                  let beforePlacemarks = placemarks;
                  let afterPlacemarks = beforePlacemarks.filter(
                    (v) => !v.key.includes("new")
                  );
                  afterPlacemarks.push(createNewPM);
                  setPlacemarks([...afterPlacemarks]);
                  // console.log("setting " + e._sourceEvent.originalEvent.coords);
                }}
              >
                {placemarks}
              </Map>
            </YMaps>
          ) : null}
        </div>
        <div
          className="col s12 l3"
          style={{
            padding: "20px 20px 0px 20px",
            position: "absolute",
            right: "0px",
          }}
        >
          <div className="row mt50">
            <div className="col s12">
              {searchCityCard ? (
                <div className="card blur-card">
                  <div className="card-content">
                    <div className="row nm mb20">
                      <div className="col s12 l500">🔍 Поиск событий</div>
                    </div>
                    <div className="row nm">
                      <Autocomplete
                        id="filter-location"
                        name="filter-location"
                        options={{
                          data: locationOptions,
                          onAutocomplete: (location) => {
                            setEventLocation(location);
                          },
                        }}
                        title={cityTitle}
                        label={"Город"}
                        placeholder={"Екатеринбург"}
                        s={9}
                        onChange={onInputCity}
                        value={eventLocation}
                        onBlur={() => {
                          // setGeoInputStates();
                        }}
                      />
                      <div className="col s3">
                        <Button
                          className="iq-btn mt15"
                          onClick={setQueryFilters}
                        >
                          Ок
                        </Button>
                      </div>

                      <div className="col s12 l500 mb20">
                        ⚡ Быстрые действия
                      </div>
                      <div className="col s12 center-align">
                        <button
                          className="btn btn-flat iq-btn col s12"
                          onClick={() => {
                            navigate("/users?active=1");
                          }}
                        >
                          Найти собеседника рядом
                        </button>
                      </div>

                      <div className="col s12 center-align mt15">
                        <button
                          className="btn btn-flat iq-btn col s12"
                          onClick={toggleSearchCityCard}
                        >
                          Смотреть события на карте
                        </button>
                      </div>

                      <div className="col s12 center-align mt15">
                        <button
                          className="btn btn-flat iq-btn col s12"
                          onClick={() => {
                            navigate("/events/flow?format=2");
                          }}
                        >
                          Смотреть онлайн-события
                        </button>
                      </div>

                      <div className="col s12 center-align mt15">
                        <button
                          className="btn btn-flat iq-btn col s12"
                          onClick={() => {
                            navigate("/events/create");
                          }}
                        >
                          Создать событие
                        </button>
                      </div>

                      <div className="col s12 center-align mt15">
                        <button
                          className="btn btn-flat iq-btn pastel-btn col s12"
                          onClick={toggleSearchCityCard}
                        >
                          Свернуть окно
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="search-city-btn pulse"
                  onClick={toggleSearchCityCard}
                >
                  <i className="material-icons-outlined chat-btn-icon">
                    travel_explore
                  </i>
                </div>
              )}
            </div>
          </div>
          <div className="row nm">
            <div className="col s12">
              <div
                style={
                  {
                    // height: window.innerHeight - 230,
                    // position: "relative",
                  }
                }
              >
                <div
                  style={{
                    maxHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  <EventList
                    events={events}
                    setMapState={setMapState}
                    mapState={ymapState}
                  />
                  {/* <div
                    style={{ backgroundColor: "red", height: "10px" }}
                    ref={lastEventElement}
                  ></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
