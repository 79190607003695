import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import RequestService from "../api/RequestService";
import noImage from "../images/no-image.png";
import noAvatar from "../images/no-avatar.png";
import moment from "moment";
import "moment/locale/ru";
import {
  copyTextToClipboard,
  formatNumber,
  parseForm,
  toTimeZone,
} from "../utils/service";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import PackageButton from "../components/UI/ParticipationPackage/PackageButton/PackageButton";
import "materialize-css";
import { Modal, Button, Icon, Select, TextInput } from "react-materialize";
import PackageOptionList from "../components/UI/ParticipationPackage/PackageOption/PackageOptionList";
import PackageScreen from "../components/UI/ParticipationPackage/PackageScreen/PackageScreen";
import { useFetching } from "../hooks/useFetching";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import ButtonPanel from "../components/UI/Button/ButtonPanel/ButtonPanel";
import { AuthContext } from "../context";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import Verified from "../components/UI/Verified/Verified";
import NewComplaint from "../components/UI/Complaints/NewComplaint/NewComplaint";
import Alert from "../components/UI/Alert/Alert";
import useConfirm from "../context/ConfirmDialog";

const Event = () => {
  const [eventData, setEventData] = useState({});
  const [eventError, setEventError] = useState(null);
  const [chosenPackage, setChosenPackage] = useState({});
  const [eventRoles, setEventRoles] = useState([]);
  // const [isBookmarked, setBookmarked] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    fetchEventData();
  }, []);

  const getEvent = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.getEvent(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      setEventError(response.message);
      return;
    }

    let participantsCount = 0;
    if (response.result.participation_packages) {
      response.result.participation_packages.forEach((pack) => {
        participantsCount += pack.participants_count;
      });
    }
    response.result.participants_count = participantsCount;

    response.result.participation_packages =
      response.result.is_owner || response.result.role == 2
        ? response.result.participation_packages.filter((p) => !p.deleted_at)
        : response.result.participation_packages.filter(
            (p) => !p.deleted_at && !p.is_expired
          );
    const pp = response.result.participation_packages.sort(
      (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
    )[0];
    // console.log(response.result.participation_packages);
    // console.log(
    //   response.result.participation_packages.reduce(
    //     (prev, cur) => (prev += cur.is_expired ? cur.is_expired : 0),
    //     0
    //   ) == response.result.participation_packages.length
    // );
    // const minPrice = pp ? pp.price : null;
    let meetingDates = [];
    if (response.result.participation_packages) {
      response.result.participation_packages.forEach((pack) => {
        meetingDates.push(
          moment(new Date(pack.datetime).getTime()).format("DD.MM.YYYY")
        );
      });
    }
    meetingDates = [...new Set(meetingDates)];
    response.result.meeting_dates = meetingDates;
    // console.log(response.result);

    setEventData(response.result);

    // console.log(
    //   new Date(response.result.participation_packages[0].datetime).getTime()
    // );
    // console.log(response.result.participation_packages);

    let rolesArr = [];
    response.result.sharing_roles.forEach((role) => {
      rolesArr.push(
        <div className="col s12" key={role.alias}>
          <div
            className="divider mt15 mb15"
            style={{ backgroundColor: "var(--text-tertiary-color)" }}
          ></div>
          <div className="row nm">
            <div className="col s12">
              <span className="m500">Пользователь: </span>
              <Link className="iq-link" to={"/users/" + role.alias}>
                {role.name}
              </Link>
            </div>
            <div className="col s12">
              <span className="m500">Юзернейм: </span>
              <span>@{role.alias}</span>
            </div>
            <div className="col s12">
              <span className="m500">Роль: </span>
              {role.role == 2
                ? "редактор"
                : role.role == 1
                ? "контролер"
                : "неизвестно"}
            </div>
            <div className="col s12 mt15">
              <Button
                className="iq-btn"
                onClick={() => {
                  removeSharingRole(role.name, role.alias);
                }}
              >
                Удалить роль
              </Button>
            </div>
          </div>
        </div>
      );
    });

    setEventRoles(rolesArr);
  };

  const choosePackage = (packageData) => {
    setChosenPackage(packageData);
  };

  const unpublishEvent = async () => {
    // if (
    //   window.confirm(
    //     "Вы действительно хотите снять событие с публикации? После этого понадобится повторная модерация."
    //   )
    // )
    const choice = await confirm({
      description:
        "Вы действительно хотите снять событие с публикации? После этого понадобится повторная модерация.",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.unpublishEvent(params.id)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        // alert(response.message);
        await confirm({
          description: response.message,
          isAlert: true,
        });

        return;
      }

      await confirm({
        description: "Событие снято с публикации",
        isAlert: true,
      });
      // alert("Событие снято с публикации");

      fetchEventData();
    }
  };

  const cancelEvent = async () => {
    // if (
    //   window.confirm(
    //     "Вы действительно хотите отменить/перенести мероприятие и разослать email об этом всем участникам? Ваше мероприятие будет снято с публикации, чтобы вы могли внести правки — далее потребуется повторная модерация."
    //   )
    // ) {
    const choice = await confirm({
      description:
        "Вы действительно хотите отменить/перенести мероприятие и разослать email об этом всем участникам? Ваше мероприятие будет снято с публикации, чтобы вы могли внести правки — далее потребуется повторная модерация.",
    });
    if (choice) {
      setFullScreenLoading(true);
      // not await
      const response = RequestService.cancelEvent(params.id);
      setFullScreenLoading(false);
      // console.log(response);
      // if (!response.response_code) {
      //   alert(response.message);
      //   return;
      // }

      await confirm({
        description: "Запущена отправка уведомлений",
        isAlert: true,
      });
      // alert("Запущена отправка уведомлений");

      fetchEventData();
    }
  };

  const publishEvent = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.publishEvent(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      // alert(response.message);
      await confirm({
        description: response.message,
        isAlert: true,
      });

      return;
    }

    await confirm({
      description: "Событие отправлено на модерацию",
      isAlert: true,
    });
    // alert("Событие отправлено на модерацию");

    fetchEventData();
  };

  const removeEvent = async () => {
    // if (window.confirm("Вы действительно хотите удалить событие?")) {
    const choice = await confirm({
      description: "Вы действительно хотите удалить событие?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removeEvent(params.id)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description: "Событие удалено",
        isAlert: true,
      });
      // alert("Событие удалено");

      navigate("/profile");
    }
  };

  const removePackage = async (packageId) => {
    // console.log(packageId);
    // if (window.confirm("Вы действительно хотите удалить пакет участия?")) {
    const choice = await confirm({
      description: "Вы действительно хотите удалить пакет участия?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removePackage(packageId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description: "Пакет участия удален",
        isAlert: true,
      });
      // alert("Пакет участия удален");

      fetchEventData();
    }
  };

  const addBookmark = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.addBookmark(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchEventData();
  };

  const removeBookmark = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.removeBookmark(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchEventData();
  };

  const shareEventAccess = async (e) => {
    e.preventDefault();
    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    for (let pair of sendFormData.entries()) {
      // console.log(pair);
    }
    const response = (
      await RequestService.sendForm(sendFormData, "/events/share")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    } else {
      await confirm({
        description:
          "Роль для пользователя @" +
          htmlForm["sharing-user"].value +
          " установлена.",
        isAlert: true,
      });
      // alert(
      //   "Роль для пользователя @" +
      //     htmlForm["sharing-user"].value +
      //     " установлена."
      // );
      htmlForm["sharing-user"].value = "";
    }

    fetchEventData();
  };

  const removeSharingRole = async (name, user) => {
    // if (
    //   window.confirm("Вы действительно хотите удалить роль для " + name + "?")
    // ) {
    const choice = await confirm({
      description: "Вы действительно хотите удалить роль для " + name + "?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removeSharingRole(params.id, user))
        .data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      fetchEventData();
    }
  };

  const getFeedbackQuiz = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.getFeedbackQuiz(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }
    await confirm({
      description:
        "Сейчас мы откроем опросник в новой вкладке — ваш браузер может заблокировать всплывающее окно. Если это произошло, откройте заблокированное окно, нажав на надпись «Всплывающее окно заблокировано».",
      isAlert: true,
    });
    // alert(
    //   "Сейчас мы откроем опросник в новой вкладке — ваш браузер может заблокировать всплывающее окно. Если это произошло, откройте заблокированное окно, нажав на надпись «Всплывающее окно заблокировано»."
    // );
    window.open(response.result, "_blank");
    // window.location.href = response.result;
  };

  const [fetchEventData, isEventDataLoading, eventDataError] =
    useFetching(getEvent);

  const followUser = async (user) => {
    setFullScreenLoading(true);
    const response = (await RequestService.followUser(user)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchEventData();
  };

  const unfollowUser = async (user) => {
    const choice = await confirm({
      description:
        "Вы действительно хотите отписаться от пользователя? Вы больше не будете получать уведомления о его новых событиях, встречах и занятиях.",
    });
    if (!choice) {
      return;
    }
    setFullScreenLoading(true);
    const response = (await RequestService.unfollowUser(user)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    fetchEventData();
  };

  return (
    <RegularPage error={eventError} isDataExist={eventData} hasBaseCard={false}>
      {eventData && eventData.interests ? (
        <div>
          <div className="row">
            <div className="col s12 l6 mb25">
              <div className="card animation-appear">
                <div className="card-content">
                  <div className="row">
                    <div className="col s12">
                      <button
                        className="btn flat-btn iq-btn pastel-btn"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <i className="material-icons-round left">
                          arrow_back_ios
                        </i>{" "}
                        Назад
                      </button>

                      <button
                        className="btn btn-flat pastel-btn right"
                        onClick={() => {
                          copyTextToClipboard(
                            window.location.href,
                            "Ссылка на событие скопирована!"
                          );
                        }}
                      >
                        <i className="material-icons-round">ios_share</i>
                      </button>

                      {eventData.is_owner == 1 ? (
                        <button
                          className="btn btn-flat pastel-btn right modal-trigger"
                          href="#modal-sharing-settings"
                          style={{ marginRight: "10px" }}
                        >
                          <i className="material-icons-round">groups</i>
                        </button>
                      ) : null}

                      <Modal
                        actions={[
                          <Button
                            flat
                            modal="close"
                            node="button"
                            className="iq-btn"
                          >
                            Закрыть
                          </Button>,
                        ]}
                        bottomSheet={false}
                        fixedFooter
                        header={"Настройки совместного доступа"}
                        id="modal-sharing-settings"
                        open={false}
                        options={{
                          dismissible: true,
                          endingTop: "10%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "4%",
                        }}
                      >
                        <div className="row mt30 nm">
                          <div className="col s12 l500">Созданные роли</div>
                        </div>
                        <div className="row">
                          {eventRoles.length > 0 ? (
                            eventRoles
                          ) : (
                            <div className="col s12 center-align mt20">
                              Созданных ролей пока нет
                            </div>
                          )}
                        </div>
                        <div className="row mt40 nm">
                          <div className="col s12 l500">Новая роль</div>
                        </div>
                        <div className="row">
                          <form
                            id="sharing-settings"
                            onSubmit={shareEventAccess}
                          >
                            <div className="col s12 mt15 mb15 secondary-color">
                              Вы можете поделиться доступом к данному проекту с
                              другими пользователями. Роль контролера
                              предоставляет возможность проверять билеты на
                              входе на мероприятие. Редактор может изменять
                              информацию о мероприятии, редактировать и удалять
                              пакеты участия, отправлять событие на модерацию.
                              Однако редактору запрещено отменять мероприятие и
                              удалять его.
                            </div>
                            <Select
                              icon={<Icon>manage_accounts</Icon>}
                              id="sharing-role"
                              name="sharing-role"
                              multiple={false}
                              label={"Роль пользователя"}
                              s={12}
                              options={{
                                classes: "",
                                dropdownOptions: {
                                  alignment: "left",
                                  autoTrigger: true,
                                  closeOnClick: true,
                                  constrainWidth: true,
                                  coverTrigger: false,
                                  hover: false,
                                  inDuration: 150,
                                  onCloseEnd: null,
                                  onCloseStart: null,
                                  onOpenEnd: null,
                                  onOpenStart: null,
                                  outDuration: 250,
                                },
                              }}
                            >
                              <option value="1">Контролер</option>
                              <option value="2">Редактор</option>
                            </Select>
                            <div className="col s12 mb15 secondary-color">
                              Юзернейм пользователя может быть задан системно, а
                              может быть изменен пользователем. Его можно найти
                              в ссылке на пользователя или в его профиле после
                              символа @ под аватаром. Например, из ссылки на
                              пользователя https://nott.one/users/danila можно
                              определить, что юзернейм пользователя — danila —
                              его можно указать в поле ниже.
                            </div>
                            <TextInput
                              id="sharing-user"
                              name="sharing-user"
                              s={12}
                              icon={<Icon>share</Icon>}
                              label="Юзернейм пользователя"
                              placeholder="user-AZpKxIN"
                              required
                            />
                            <input
                              type="hidden"
                              name="sharing-event-id"
                              id="sharing-event-id"
                              value={params.id}
                            />

                            <div className="col s12 center-align mt20">
                              <Button flat className="iq-btn" type="submit">
                                Поделиться доступом
                              </Button>
                            </div>
                          </form>
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="row mb10">
                    <div className="col s12 xl500">
                      {eventData.name}{" "}
                      {/* <span className="tertiary-color">
                        {" (" +
                          (eventData.age_limit ? eventData.age_limit : 0) +
                          "+)"}
                      </span> */}
                    </div>
                  </div>
                  {eventData.is_owner == 1 || eventData.role == 2 ? (
                    <div>
                      {!eventData.published_at ? (
                        <div className="row mt20">
                          <div className="col s12">
                            <div className="card alert-message">
                              <div className="card-content">
                                <div className="row nm">
                                  <div className="col s12">
                                    Событие находится в режиме черновика,
                                    поэтому оно пока не опубликовано. После
                                    отправки на публикацию, событие пройдет
                                    модерацию.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="row">
                        <div className="col s12">
                          {!eventData.published_at ? (
                            <span
                              className="iq-link publish-event-btn"
                              onClick={publishEvent}
                            >
                              отправить на публикацию
                            </span>
                          ) : (
                            <span
                              className="iq-link unpublish-event-btn"
                              onClick={unpublishEvent}
                            >
                              снять с публикации
                            </span>
                          )}{" "}
                          |{" "}
                          <span
                            className="iq-link remove-event-btn"
                            onClick={removeEvent}
                          >
                            удалить событие
                          </span>
                        </div>
                      </div> */}

                      <div className="row nm">
                        <div
                          className={
                            eventData.status_data.status == 4 ||
                            eventData.is_banned == 1
                              ? "col s12 error"
                              : eventData.status_data.status == 3
                              ? "col s12 alert"
                              : eventData.status_data.status == 2
                              ? "col s12 success-color"
                              : "col s12"
                          }
                        >
                          Статус:{" "}
                          {eventData.is_banned == 1
                            ? "заблокирован за нарушение"
                            : eventData.status_data.status == 1
                            ? "черновик"
                            : eventData.status_data.status == 2
                            ? "опубликовано"
                            : eventData.status_data.status == 3
                            ? "на модерации"
                            : eventData.status_data.status == 4
                            ? "отклонен" +
                              (eventData.status_data.message
                                ? " (" + eventData.status_data.message + ")"
                                : "")
                            : "неизвестно"}
                        </div>
                        {/* <div className="col s12 mt5">
                          Код события: {eventData.key}
                        </div> */}
                      </div>
                    </div>
                  ) : null}

                  <div className="row nm mb10">
                    {eventData.datetime ? (
                      <div
                        className={
                          eventData.participation_packages.reduce(
                            (prev, cur) =>
                              (prev += cur.is_expired ? cur.is_expired : 0),
                            0
                          ) == eventData.participation_packages.length
                            ? "col s12 mt15 error-color"
                            : "col s12 mt15 secondary-color"
                        }
                      >
                        <i
                          className="material-icons-round accent-color"
                          style={{ position: "absolute", marginTop: "-2px" }}
                        >
                          event
                        </i>
                        <span style={{ marginLeft: "30px" }}>
                          Ближайшее событие:{" "}
                          {moment(eventData.datetime).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                          <span className="tertiary-color">
                            {" (" +
                              (eventData.timezone_description
                                ? eventData.timezone_description
                                : "время местное") +
                              ")"}
                          </span>
                        </span>
                      </div>
                    ) : null}
                    {eventData.duration ? (
                      <div className="col s12 mt15 secondary-color">
                        <i
                          className="material-icons-round accent-color"
                          style={{ position: "absolute", marginTop: "-2px" }}
                        >
                          schedule
                        </i>
                        <span style={{ marginLeft: "30px" }}>
                          {eventData.duration} мин.
                        </span>
                      </div>
                    ) : null}
                    {eventData.format ? (
                      <div className="col s12 mt15 secondary-color">
                        <i
                          className="material-icons-round accent-color"
                          style={{ position: "absolute", marginTop: "-2px" }}
                        >
                          maps_home_work
                        </i>
                        <span style={{ marginLeft: "30px" }}>
                          {eventData.format == 1
                            ? "Офлайн"
                            : eventData.format == 2
                            ? "Онлайн"
                            : "Смешанный (онлайн + офлайн)"}{" "}
                          формат участия
                        </span>
                      </div>
                    ) : null}
                  </div>

                  {eventData.is_owner ? (
                    <div className="row mt15 mb10">
                      <div className="col s12">
                        <div className="card pastel-btn">
                          <div className="card-content">
                            <div className="row nm">
                              <div className="col s12">
                                <Icon
                                  style={{ position: "absolute" }}
                                  className="accent-color"
                                >
                                  directions_walk
                                </Icon>
                                <span
                                  className={
                                    eventData.is_owner || eventData.role == 2
                                      ? "iq-link"
                                      : "tertiary-color"
                                  }
                                  style={{
                                    marginLeft: "45px",
                                  }}
                                  onClick={() => {
                                    if (
                                      eventData.is_owner ||
                                      eventData.role == 2
                                    ) {
                                      navigate("/tickets/" + params.id);
                                    }
                                  }}
                                >
                                  {eventData.is_owner || eventData.role == 2
                                    ? "Смотреть участников (" +
                                      eventData.participants_count +
                                      ")"
                                    : eventData.participants_count +
                                      " чел. уже идут на мероприятие, а вы?"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mb20">
                    <div className="col s12 mt10">
                      <ButtonPanel
                        btnsArr={[
                          eventData.is_owner == 1 || eventData.role == 2
                            ? {
                                icon_name: "edit",
                                title: "Изменить",
                                on_click: function () {
                                  navigate("/events/edit/" + eventData.id);
                                },
                              }
                            : {
                                icon_name: eventData.is_bookmarked
                                  ? "bookmark_added"
                                  : "bookmark_border",
                                title: eventData.is_bookmarked
                                  ? "В закладках"
                                  : "Закладка",
                                on_click: function () {
                                  if (eventData.is_bookmarked) {
                                    removeBookmark();
                                  } else {
                                    addBookmark();
                                  }
                                },
                              },
                          eventData.is_owner == 1 || eventData.role == 2
                            ? !eventData.published_at
                              ? {
                                  icon_name: "visibility",
                                  title: "Опубликовать",
                                  on_click: function () {
                                    publishEvent();
                                  },
                                }
                              : {
                                  icon_name: "visibility_off",
                                  title: "Скрыть",
                                  on_click: function () {
                                    unpublishEvent();
                                  },
                                }
                            : {
                                icon_name: "send",
                                title: "Сообщение",
                                on_click: function () {
                                  let text = encodeURIComponent(
                                    "Здравствуйте! Пишу по поводу " +
                                      window.location.href +
                                      ". "
                                  );
                                  navigate(
                                    "/messages/" +
                                      eventData.owner_id +
                                      "?text=" +
                                      text
                                  );
                                },
                              },
                          eventData.is_owner == 0
                            ? {
                                icon_name: "thumb_down_off_alt",
                                title: "Жалоба",
                                modal: {
                                  title: "Пожаловаться",
                                  content: <NewComplaint />,
                                },
                              }
                            : {
                                icon_name: "delete_forever",
                                title: "Удалить",
                                on_click: function () {
                                  removeEvent();
                                },
                              },
                          eventData.is_owner == 1 && eventData.published_at
                            ? {
                                icon_name: "campaign",
                                title: "Уведомить об отмене",
                                on_click: function () {
                                  cancelEvent();
                                },
                              }
                            : null,
                        ]}
                      />
                    </div>
                  </div>

                  <div className="row nm">
                    <div className="col s12">
                      <img
                        src={
                          eventData.cover_img_url
                            ? eventData.cover_img_url
                            : noImage
                        }
                        className={
                          !eventData.cover_img_url ? "image-theme-filter" : null
                        }
                        alt=""
                        style={{ width: "100%", borderRadius: "15px" }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    {eventData.interests.length > 0 ? (
                      <div className="col s12 mt15">
                        {eventData.interests.map((interest) => (
                          <div
                            className="chip hand-cursor"
                            key={interest.name}
                            onClick={() => {
                              let urlSearchParams = new URLSearchParams();
                              urlSearchParams.append(
                                "interests[]",
                                interest.interest_id
                              );
                              navigate(
                                "/events/flow?" + urlSearchParams.toString()
                              );
                            }}
                          >
                            {"#" + interest.name}
                          </div>
                        ))}
                      </div>
                    ) : null}

                    {/* {eventData.is_owner ? (
                      <div className="col s12 mt25">
                        <div className="card alert-message">
                          <div className="card-content">
                            <div className="row nm">
                              <div className="col s12">
                                Обратите внимание, что возможность редактировать
                                параметры мероприятия заблокируется за 24 часа
                                до его начала. Пожалуйста, не путайте
                                участников. Если у вас что-то изменилось,
                                найдите возможность сообщить каждому.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null} */}

                    {/* <div className="col s12 mt15">
                      <i
                        className="material-icons-round"
                        style={{ position: "absolute", marginTop: "-2px" }}
                      >
                        groups
                      </i>

                      <span
                        style={{ marginLeft: "30px" }}
                        className={eventData.is_owner ? "iq-link" : ""}
                        onClick={() => {
                          if (eventData.is_owner) {
                            navigate("/tickets/" + params.id);
                          }
                        }}
                      >
                        {eventData.participants_count} чел. уже идут
                      </span>
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="col s12">
                      <div className="card pastel-btn">
                        <div className="card-content">
                          <div className="row nm">
                            <Icon
                              className="accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "3px",
                                marginLeft: "5px",
                              }}
                            >
                              stars
                            </Icon>
                            <div
                              className="col s12 l500 mb10"
                              style={{ marginLeft: "30px" }}
                            >
                              Рейтинг события
                            </div>

                            <div className="col s12 tertiary-color mb20">
                              После окончания мероприятия мы рассылаем
                              участникам опрос — так формируется оценка
                            </div>
                            <div className="col s12 xxxl500 accent-color">
                              <i
                                className="material-icons-round"
                                style={{
                                  fontSize: "40px",
                                  position: "absolute",
                                  marginTop: "-4px",
                                }}
                              >
                                grade
                              </i>
                              <span style={{ marginLeft: "50px" }}>
                                {formatNumber(eventData.rating.toFixed(2))}
                              </span>
                            </div>
                            <div className="col s12 mt15">
                              <Link className="iq-link" to={"reviews"}>
                                <i
                                  className="material-icons tiny accent-color"
                                  style={{
                                    position: "absolute",
                                    marginTop: "3px",
                                  }}
                                >
                                  reviews
                                </i>
                                <span style={{ marginLeft: "25px" }}>
                                  Смотреть отзывы ({eventData.number_of_ratings}
                                  )
                                </span>
                              </Link>
                            </div>
                            {/* {eventData.visited_at &&
                            new Date().getTime() / 1000 >
                              new Date(eventData.datetime).getTime() / 1000 +
                                eventData.duration * 60 ? (
                              <div>
                                <div className="col s12 mt15 tertiary-color">
                                  Вы посетили событие{" "}
                                  {moment(eventData.visited_at).format(
                                    "DD.MM.YYYY HH:mm"
                                  )}{" "}
                                  (МСК)
                                </div>
                                <div className="col s12 mt20">
                                  <Button
                                    className="iq-btn"
                                    onClick={getFeedbackQuiz}
                                  >
                                    Оценить событие
                                  </Button>
                                </div>
                              </div>
                            ) : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12">
                      <div className="card pastel-btn">
                        <div className="card-content">
                          <div className="row nm">
                            <Icon
                              className="accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "3px",
                                marginLeft: "5px",
                              }}
                            >
                              account_circle
                            </Icon>
                            <div
                              className="col s12 l500 mb20"
                              style={{ marginLeft: "30px" }}
                            >
                              Организатор
                            </div>
                          </div>
                          <div className="row nm">
                            <Link to={"/users/" + eventData.owner_id}>
                              <div className="col s4 l3 center-align">
                                <div
                                  className="circular-image pastel-shadow"
                                  style={{
                                    width: "55px",
                                    height: "55px",
                                    position: "relative",
                                    margin: "auto",
                                  }}
                                >
                                  <div
                                    className={
                                      eventData.owner_avatar_url
                                        ? "circular-image"
                                        : "circular-image image-theme-filter"
                                    }
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        (eventData.owner_avatar_url
                                          ? eventData.owner_avatar_url
                                          : noAvatar) +
                                        ")",
                                      height: "55px",
                                      width: "55px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </Link>

                            <div className="col s8 l9">
                              <Link to={"/users/" + eventData.owner_id}>
                                <div className="row nm">
                                  <div className="col s12 m500">
                                    {eventData.owner_name}{" "}
                                    <Verified
                                      ml={5}
                                      mt={-4}
                                      themeColor={true}
                                      isOriginal={eventData.owner_is_original}
                                      isVerified={eventData.owner_is_verified}
                                    />
                                  </div>
                                  <div className="col s12 mt5 accent-color">
                                    {"@" + eventData.owner_id}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12">
                      <div className="card pastel-btn">
                        <div className="card-content">
                          <div className="row nm">
                            <Icon
                              className="accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "2px",
                                marginLeft: "5px",
                              }}
                            >
                              description
                            </Icon>
                            <div
                              className="col s12 l500 mb10"
                              style={{ marginLeft: "30px" }}
                            >
                              Описание
                            </div>
                            <div className="col s12 secondary-color">
                              {eventData.description
                                ? eventData.description
                                : "Информация не указана"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row nm">
                    <div className="col s12">
                      <div className="card pastel-btn">
                        <div className="card-content">
                          <div className="row nm">
                            <Icon
                              className="accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "3px",
                                marginLeft: "5px",
                              }}
                            >
                              group
                            </Icon>
                            <div
                              className="col s12 l500 mb10"
                              style={{ marginLeft: "30px" }}
                            >
                              Для кого
                            </div>
                            <div className="col s12 secondary-color">
                              {eventData.audience
                                ? eventData.audience
                                : "Информация не указана"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col s12 l6">
              <div className="card animation-appear">
                <div className="card-content">
                  {eventData.latitude && eventData.longitude ? (
                    <div>
                      <div className="row">
                        <div className="col s12 xl500">Место на карте</div>
                      </div>

                      {eventData.location ? (
                        <div className="row secondary-color">
                          <div className="col s12">
                            <i
                              className="material-icons accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "-2px",
                              }}
                            >
                              place
                            </i>
                            <span style={{ marginLeft: "30px" }}>
                              {eventData.location}
                            </span>
                          </div>
                        </div>
                      ) : null}
                      {eventData.address ? (
                        <div className="row">
                          <div className="col s12 secondary-color">
                            <i
                              className="material-icons-outlined accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "-2px",
                              }}
                            >
                              apartment
                            </i>
                            <span style={{ marginLeft: "30px" }}>
                              {eventData.address}
                            </span>
                          </div>
                        </div>
                      ) : null}

                      {eventData.place ? (
                        <div className="row">
                          <div className="col s12 secondary-color">
                            <i
                              className="material-icons-outlined accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "-2px",
                              }}
                            >
                              near_me
                            </i>
                            <span style={{ marginLeft: "30px" }}>
                              {eventData.place}
                            </span>
                          </div>
                        </div>
                      ) : null}

                      {eventData.latitude && eventData.longitude ? (
                        <div className="row">
                          <div className="col s12 secondary-color">
                            <i
                              className="material-icons-outlined accent-color"
                              style={{
                                position: "absolute",
                                marginTop: "-2px",
                              }}
                            >
                              gps_fixed
                            </i>
                            <span style={{ marginLeft: "30px" }}>
                              {Number(eventData.latitude) +
                                ", " +
                                Number(eventData.longitude)}
                            </span>
                          </div>
                        </div>
                      ) : null}

                      <div className="row mb25">
                        <div className="col s12">
                          <YMaps>
                            <Map
                              defaultState={{
                                center: [
                                  eventData.latitude,
                                  eventData.longitude,
                                ],
                                zoom: 16,
                                controls: ["zoomControl"],
                              }}
                              // state={ymapState}
                              width={"100%"}
                              height={"350px"}
                              modules={["control.ZoomControl"]}
                              onLoad={(api) => {
                                // console.log(api);
                              }}
                            >
                              <Placemark
                                modules={["geoObject.addon.balloon"]}
                                geometry={[
                                  eventData.latitude,
                                  eventData.longitude,
                                ]}
                                properties={{
                                  balloonContentBody: eventData.location
                                    ? eventData.location
                                    : "Нет подробной информации о месте проведения",
                                }}
                                options={{
                                  preset: "islands#dotIcon",
                                  iconColor: "#3d50fa",
                                }}
                                key={eventData.id}
                              />
                            </Map>
                          </YMaps>
                          {/* <img
                          src={
                            "https://static-maps.yandex.ru/1.x/?ll=" +
                            eventData.longitude +
                            "," +
                            eventData.latitude +
                            "&size=650,450&z=17&l=map&pt=" +
                            eventData.longitude +
                            "," +
                            eventData.latitude +
                            ",comma"
                          }
                          className="image-theme-filter"
                          style={{ width: "100%" }}
                          alt=""
                        /> */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row mb10">
                    <div className="col s12 xl500">Расписание</div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      Выберите подходящий вам вариант из доступных ниже. Будьте
                      внимательны: на одно и то же время может быть доступно
                      несколько вариантов участия, которые отличаются
                      содержанием (например, стандартное участие и расширенное).
                    </div>
                  </div>

                  {!eventData.is_owner && eventData.role != 2 ? (
                    <div className="row">
                      <div className="col s12">
                        <div className="card alert-message">
                          <div className="card-content">
                            <div className="row nm">
                              <div className="col s12 l500">
                                <i
                                  className="material-icons alert-color"
                                  style={{
                                    position: "absolute",
                                    marginTop: "2px",
                                  }}
                                >
                                  thumb_up
                                </i>
                                <span style={{ marginLeft: "35px" }}>
                                  Совет
                                </span>
                              </div>
                              <div className="col s12 mt5">
                                Подпишитесь на организатора, чтобы получать
                                уведомления о новых событиях, встречах и
                                занятиях — так вы не ничего не пропустите и
                                займете лучшие места.
                              </div>

                              {eventData.follow_owner > -1 ? (
                                <div className="col s12 center-align mt15">
                                  <Button
                                    flat
                                    className={
                                      eventData.follow_owner === null
                                        ? "iq-btn"
                                        : "pastel-btn iq-btn"
                                    }
                                    onClick={() => {
                                      if (eventData.follow_owner === null) {
                                        followUser(eventData.owner_id);
                                      } else {
                                        unfollowUser(eventData.owner_id);
                                      }
                                    }}
                                  >
                                    {eventData.follow_owner === null
                                      ? eventData.is_private == 1
                                        ? "Заявка на подписку"
                                        : "Подписаться на обновления"
                                      : eventData.follow_owner === 0
                                      ? "Заявка отправлена"
                                      : "Вы подписаны на обновления"}
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {eventData.is_owner || eventData.role == 2 ? (
                    <div className="row">
                      <div className="col s12">
                        <div className="card alert-message">
                          <div className="card-content">
                            <div className="row nm">
                              <div className="col s12">
                                В этом разделе пользователи могут купить билет
                                на ваше мероприятие. Узнайте{" "}
                                <a
                                  className="iq-link"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://help.nott.one/2023/11/17/how-to-check-tickets"
                                >
                                  здесь
                                </a>
                                , как проверять билеты участников и отмечать
                                присутствующих.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    {eventData.participation_packages.length > 0 ||
                    eventData.is_owner == 1 ||
                    eventData.role == 2 ? (
                      eventData.meeting_dates.map((md) => {
                        let datePacks = [];
                        datePacks.push(
                          <div
                            className="col s12 xl500 mb15 mt5"
                            key={Math.random() * 100000}
                          >
                            {moment(
                              new Date(
                                md.replace(
                                  /(\d{2}).(\d{2}).(\d{4})/,
                                  "$2/$1/$3"
                                )
                              )
                            )
                              .locale("ru")
                              .format("LL")}
                          </div>
                        );
                        eventData.participation_packages.forEach(
                          (el, index, arr) => {
                            let packMargin = 20;
                            if (index === arr.length - 1) {
                              packMargin = 0;
                            }
                            // console.log(el);

                            if (
                              md ==
                              moment(new Date(el.datetime).getTime()).format(
                                "DD.MM.YYYY"
                              )
                            ) {
                              datePacks.push(
                                <PackageButton
                                  pack={el}
                                  key={el.name}
                                  marginBottom={packMargin}
                                  choosePackage={choosePackage}
                                  hasControls={
                                    eventData.is_owner == 1 ||
                                    eventData.role == 2
                                      ? true
                                      : false
                                  }
                                  editPackage={() => {
                                    navigate(
                                      "/packages/edit?package=" +
                                        el.id +
                                        "&event=" +
                                        params.id
                                    );
                                  }}
                                  removePackage={() => {
                                    removePackage(el.id);
                                  }}
                                />
                              );
                            }
                          }
                        );

                        return datePacks;
                        // eventData.participation_packages.map(
                        //   (el, index, arr) => {
                        //     let packMargin = 20;
                        //     if (index === arr.length - 1) {
                        //       packMargin = 0;
                        //     }
                        //     console.log(el);

                        //     return (
                        //       <PackageButton
                        //         pack={el}
                        //         key={el.name}
                        //         marginBottom={packMargin}
                        //         choosePackage={choosePackage}
                        //         hasControls={
                        //           eventData.is_owner == 1 || eventData.role == 2
                        //             ? true
                        //             : false
                        //         }
                        //         editPackage={() => {
                        //           navigate(
                        //             "/packages/edit?package=" +
                        //               el.id +
                        //               "&event=" +
                        //               params.id
                        //           );
                        //         }}
                        //         removePackage={() => {
                        //           removePackage(el.id);
                        //         }}
                        //       />
                        //     );
                        //   }
                        // );
                      })
                    ) : (
                      <div
                        className="col s12 error-color center-align"
                        style={{ paddingTop: "15px" }}
                      >
                        <div
                          className="error-message"
                          style={{ padding: "15px" }}
                        >
                          Запись на встречу закрыта, так как она уже прошла или
                          еще не была назначена
                        </div>
                      </div>
                    )}
                    {eventData.is_owner || eventData.role == 2 ? (
                      <div className="col s12 center-align mt25">
                        <button
                          className="btn iq-btn"
                          onClick={() => {
                            navigate("/packages/create?event=" + params.id);
                          }}
                        >
                          <i className="material-icons-outlined left">
                            add_circle
                          </i>
                          Добавить
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            actions={[
              <Button flat modal="close" node="button" className="iq-btn">
                Закрыть
              </Button>,
            ]}
            bottomSheet={false}
            fixedFooter
            header="Принять участие"
            id="modal-package"
            open={false}
            options={{
              dismissible: true,
              endingTop: "10%",
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: "4%",
            }}
            // root={[object HTMLBodyElement]}
            // trigger={<Button node="button">MODAL WITH FIXED FOOTER</Button>}
          >
            <PackageScreen pack={chosenPackage} />
          </Modal>
        </div>
      ) : null}
    </RegularPage>
  );
};

export default Event;
