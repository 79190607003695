import moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import noAvatar from "../../../images/no-avatar.png";
import useConfirm from "../../../context/ConfirmDialog";
import RequestService from "../../../api/RequestService";
import { AuthContext } from "../../../context";
import {
  Modal,
  Button,
  Icon,
  TextInput,
  Textarea,
  Carousel,
} from "react-materialize";
import {
  copyTextToClipboard,
  parseForm,
  textWithLinks,
  youtubeVideoIdParser,
} from "../../../utils/service";
import M from "materialize-css";
import NewComplaint from "../Complaints/NewComplaint/NewComplaint";
import FullScreenImage from "../Image/FullScreenImage";
import OnlineIndicator from "../indicators/OnlineIndicator";

const Posts = ({
  posts,
  isLoading,
  getPosts,
  isCurrentUser = 0,
  onSurface = true,
}) => {
  const confirm = useConfirm();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [currentPostText, setCurrentPostText] = useState("");
  const [currentPostId, setCurrentPostId] = useState("");
  const [openedImgUrl, setOpenedImgUrl] = useState(null);

  // console.log(posts);

  const removePost = async (postId) => {
    const choice = await confirm({
      description: "Вы действительно хотите удалить запись?",
    });

    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removePost(postId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });

        return;
      }

      await confirm({
        description: "Запись удалена",
        isAlert: true,
      });
      // alert("Событие удалено");

      getPosts();
    }
  };

  const savePost = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    let sendFormData = parseForm(htmlForm);

    const response = (
      await RequestService.sendForm(sendFormData, "/posts/edit")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    M.Modal.getInstance(document.getElementById("modal-edit-post")).close();
    getPosts();
  };

  const failure = (
    <div>
      <div className="row nm mt15">
        <div className="col s12 center-align">
          Информации нет или при загрузке произошла ошибка
        </div>
      </div>
    </div>
  );
  if (!posts && !isLoading) {
    return failure;
  }
  if (posts && !isLoading) {
    if (posts.length == 0) {
      return failure;
    }
  }
  let flow = null;
  if (posts) {
    // console.log(posts);

    // console.log(getComputedStyle(document.body).getPropertyValue("--img-bg"));
    // if (document.getElementById("carousel-0")) {
    //   let carouselInstance = M.Carousel.init(
    //     document.getElementById("carousel-0"),
    //     {
    //       onCycleTo: (current) => {
    //         console.log(current.getAttribute("src"));
    //         document.documentElement.style.setProperty(
    //           "--img-bg",
    //           "url(" + current.getAttribute("src") + ")"
    //         );

    //         console.log(
    //           getComputedStyle(document.body).getPropertyValue("--img-bg")
    //         );
    //       },
    //     }
    //   );

    //   // carouselInstance.duration;
    //   // carouselInstance.options.onCycleTo();

    //   // carouselInstance.options.onCycleTo((current) => {
    //   //   console.log(current);
    //   // });
    // }

    let postElements = [];
    for (let i = 0; i < posts.length; i++) {
      let outputPostText = [];

      let ytLink = posts[i].text
        .split(" ")
        .filter(
          (word) =>
            word.includes("https://www.youtube.com/watch") ||
            word.includes("https://youtu.be")
        )[0];

      // console.log(ytLink);

      outputPostText = textWithLinks(posts[i].text);

      postElements.push(
        <div className="col s12" key={posts[i].id}>
          <div
            className={
              onSurface
                ? "card mb30 bg animation-appear"
                : "card mb30 animation-appear"
            }
          >
            <div className="card-content">
              <div className="row nm">
                <Link to={"/users/" + posts[i].alias}>
                  <div className="col s3 l1">
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <div
                        className={
                          posts[i].avatar_img_url
                            ? "circular-image"
                            : "circular-image image-theme-filter"
                        }
                        style={{
                          backgroundImage:
                            "url(" +
                            (posts[i].avatar_img_url
                              ? posts[i].avatar_img_url
                              : noAvatar) +
                            ")",
                          height: "45px",
                          width: "45px",
                        }}
                      ></div>
                      <OnlineIndicator
                        ml={35}
                        mt={-15}
                        isOnline={posts[i].user_is_online}
                      />
                    </div>
                  </div>
                </Link>
                <div className="col s9 l11">
                  <Link to={"/users/" + posts[i].alias}>
                    <div className="row nm">
                      <div className="col s12 m500">{posts[i].name}</div>
                      <div className="col s12 s300 mt5 tertiary-color">
                        {moment(posts[i].timestamp).format("DD.MM.YYYY HH:mm") +
                          " (МСК)"}
                      </div>
                    </div>
                  </Link>
                </div>
                {posts[i].edited_at ? (
                  <div
                    className="col s12 mt15 s300"
                    style={{ fontStyle: "italic" }}
                  >
                    {"изменено " +
                      moment(posts[i].edited_at).format("DD.MM.YYYY HH:mm") +
                      " (МСК)"}
                  </div>
                ) : (
                  ""
                )}
                <div className="col s12 secondary-color mt15">
                  {outputPostText}
                </div>

                {ytLink ? (
                  <div className="col s12 mt25">
                    <div style={{ width: "100%", aspectRatio: "16 / 9" }}>
                      <iframe
                        src={
                          "https://www.youtube.com/embed/" +
                          youtubeVideoIdParser(ytLink)
                        }
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </div>
                ) : null}

                {posts[i].files.length > 0 ? (
                  <div className="col s12">
                    <div className="mt20">
                      <Carousel
                        carouselId={"carousel-" + i}
                        className="white-text center"
                        options={{
                          fullWidth: true,
                          indicators: true,
                          noWrap: true,
                        }}
                      >
                        {posts[i].files.map((f) => (
                          <div key={f}>
                            <div
                              className="carousel-img-bg"
                              style={{
                                backgroundImage: "url(" + f + ")",
                              }}
                            ></div>
                            <img
                              src={f}
                              alt=""
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                              onClick={() => {
                                setOpenedImgUrl(f);
                              }}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                ) : null}

                {isCurrentUser ? (
                  <div className="col s12 right-align mt35">
                    <Button
                      className="white-btn modal-trigger"
                      href="#modal-edit-post"
                      onClick={() => {
                        setCurrentPostText(posts[i].text);
                        setCurrentPostId(posts[i].id);
                        document.getElementById("post-edit-text").style.height =
                          "150px";
                      }}
                    >
                      <Icon>edit</Icon>
                    </Button>
                    <Button
                      className="white-btn"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        copyTextToClipboard(
                          window.location.origin + "/posts/" + posts[i].id,
                          "Ссылка на запись скопирована!"
                        );
                      }}
                    >
                      <Icon>ios_share</Icon>
                    </Button>
                    <Button
                      className="white-btn"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        removePost(posts[i].id);
                      }}
                    >
                      <Icon>delete</Icon>
                    </Button>
                  </div>
                ) : (
                  <div className="col s12 right-align mt35">
                    <Button
                      className="white-btn"
                      onClick={() => {
                        copyTextToClipboard(
                          window.location.origin + "/posts/" + posts[i].id,
                          "Ссылка на запись скопирована!"
                        );
                      }}
                    >
                      <Icon>ios_share</Icon>
                    </Button>
                    <Button
                      className="white-btn modal-trigger"
                      style={{ marginLeft: "10px" }}
                      href="#modal-post-complaint"
                      onClick={() => {
                        setCurrentPostId(posts[i].id);
                      }}
                    >
                      <Icon>thumb_down</Icon>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    flow = postElements;
  } else {
    return (
      <div className="row nm">
        <div className="col s12 center-align">Ошибка</div>
      </div>
    );
  }

  return (
    <div className="row nm">
      {flow}
      <Modal
        actions={[
          <Button flat modal="close" node="button" className="iq-btn">
            Закрыть
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter
        header="Редактирование записи"
        id="modal-edit-post"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        // root={[object HTMLBodyElement]}
        // trigger={<Button node="button">MODAL WITH FIXED FOOTER</Button>}
      >
        <form onSubmit={savePost} className="mt20">
          <div className="row nm">
            <Textarea
              id="post-edit-text"
              name="post-edit-text"
              label={"Ваша запись"}
              placeholder={"до 3000 символов"}
              s={12}
              style={{ marginBottom: "0px" }}
              onChange={(e) => {
                setCurrentPostText(e.target.value);
              }}
              value={currentPostText}
              required
            />
          </div>

          <input
            type="hidden"
            name="post-id"
            id="post-id"
            value={currentPostId}
          />

          <div className="col s12 center-align">
            <Button className="iq-btn col s12" type="submit">
              Сохранить
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        actions={[
          <Button flat modal="close" node="button" className="iq-btn">
            Закрыть
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter
        header={"Пожаловаться"}
        id="modal-post-complaint"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
      >
        <NewComplaint targetObject={currentPostId} />
      </Modal>
      <FullScreenImage
        imgUrl={openedImgUrl}
        closeImgFn={() => setOpenedImgUrl(null)}
      />
    </div>
  );
};

export default Posts;
