import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { AuthContext } from "../context";
import useConfirm from "../context/ConfirmDialog";

const SettingsPrivacy = () => {
  const [userData, setUserData] = useState(null);
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  const getUser = async () => {
    const response = (await RequestService.getUser()).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setUserData(response.result);
  };

  const setNotifyAbout = async (obj) => {
    setFullScreenLoading(true);
    const response = (await RequestService.setNotifications(obj)).data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    await getUser();
    setFullScreenLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <RegularPage
      isDataExist={userData}
      hasBaseCard={true}
      header={"Уведомления"}
      isPrimaryPage={false}
      backText={"Назад"}
      hasAppearanceAnimation={true}
    >
      {userData ? (
        <div className="row mt25">
          <div className="col s12 l500">
            <i
              className="material-icons-outlined accent-color"
              style={{ position: "absolute", marginTop: "3px" }}
            >
              mail
            </i>
            <span style={{ marginLeft: "35px" }}>Уведомления по email</span>
          </div>
          <div className="col s12 mt10">
            Уточните, хотите ли вы получать сообщения на ваш email по указанным
            ниже поводам. Помните, что отправку важных уведомлений (смена
            пароля, отмена мероприятий и др.) отключить нельзя.
          </div>
          <div className="col s12 mt20">
            <div className="switch">
              <label style={{ fontSize: "100%" }}>
                <input
                  checked={
                    userData.email_notifications_new_events == 1
                      ? "checked"
                      : ""
                  }
                  type="checkbox"
                  id="settings-notify-about-new-near-events"
                  name="settings-notify-about-new-near-events"
                  onChange={(e) => {
                    e.target.checked
                      ? setNotifyAbout({
                          email_notifications_new_events: 1,
                        })
                      : setNotifyAbout({
                          email_notifications_new_events: 0,
                        });
                  }}
                />
                <span className="lever" style={{ margiLeft: "0px" }}></span>
                <span className="tertiary-color">
                  Уведомлять о новых событиях недалеко от меня
                </span>
              </label>
            </div>
          </div>

          <div className="col s12 mt20">
            <div className="switch">
              <label style={{ fontSize: "100%" }}>
                <input
                  checked={
                    userData.email_notifications_new_messages == 1
                      ? "checked"
                      : ""
                  }
                  type="checkbox"
                  id="settings-notify-about-new-messages"
                  name="settings-notify-about-new-messages"
                  onChange={(e) => {
                    e.target.checked
                      ? setNotifyAbout({
                          email_notifications_new_messages: 1,
                        })
                      : setNotifyAbout({
                          email_notifications_new_messages: 0,
                        });
                  }}
                />
                <span className="lever" style={{ margiLeft: "0px" }}></span>
                <span className="tertiary-color">
                  Уведомлять о новых сообщениях
                </span>
              </label>
            </div>
          </div>

          <div className="col s12 mt20">
            <div className="switch">
              <label style={{ fontSize: "100%" }}>
                <input
                  checked={
                    userData.email_notifications_new_participants == 1
                      ? "checked"
                      : ""
                  }
                  type="checkbox"
                  id="settings-notify-about-new-participants"
                  name="settings-notify-about-new-participants"
                  onChange={(e) => {
                    e.target.checked
                      ? setNotifyAbout({
                          email_notifications_new_participants: 1,
                        })
                      : setNotifyAbout({
                          email_notifications_new_participants: 0,
                        });
                  }}
                />
                <span className="lever" style={{ margiLeft: "0px" }}></span>
                <span className="tertiary-color">
                  Уведомлять о новых участниках моих событий
                </span>
              </label>
            </div>
          </div>

          <div className="col s12 mt20">
            <div className="switch">
              <label style={{ fontSize: "100%" }}>
                <input
                  checked={
                    userData.email_notifications_new_updates == 1
                      ? "checked"
                      : ""
                  }
                  type="checkbox"
                  id="settings-notify-about-new-updates"
                  name="settings-notify-about-new-updates"
                  onChange={(e) => {
                    e.target.checked
                      ? setNotifyAbout({
                          email_notifications_new_updates: 1,
                        })
                      : setNotifyAbout({
                          email_notifications_new_updates: 0,
                        });
                  }}
                />
                <span className="lever" style={{ margiLeft: "0px" }}></span>
                <span className="tertiary-color">
                  Уведомлять об обновлениях пользователей и организаторов, на
                  которых вы подписаны
                </span>
              </label>
            </div>
          </div>

          <div className="col s12 mt20">
            <div className="switch">
              <label style={{ fontSize: "100%" }}>
                <input
                  checked={
                    userData.email_notifications_users_around == 1
                      ? "checked"
                      : ""
                  }
                  type="checkbox"
                  id="settings-notify-about-users-around"
                  name="settings-notify-about-users-around"
                  onChange={(e) => {
                    e.target.checked
                      ? setNotifyAbout({
                          email_notifications_users_around: 1,
                        })
                      : setNotifyAbout({
                          email_notifications_users_around: 0,
                        });
                  }}
                />
                <span className="lever" style={{ margiLeft: "0px" }}></span>
                <span className="tertiary-color">
                  Уведомлять о пользователях рядом (нетворкинг)
                </span>
              </label>
            </div>
          </div>
        </div>
      ) : null}
    </RegularPage>
  );
};

export default SettingsPrivacy;
