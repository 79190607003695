import React, { useState, useEffect, useRef, useContext } from "react";
import RequestService from "../api/RequestService";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import ErrorMessage from "../components/UI/ErrorMessage/ErrorMessage";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import UserSmallCardList from "../components/UI/Users/UserSmallCard/UserSmallCardList";
import { useFetching } from "../hooks/useFetching";
import { useSearchParamsArray } from "../hooks/useSearchParamsArray";
import { useObserver } from "../hooks/useObserver";
import Filter from "../components/UI/Filter/Filter";
import {
  Autocomplete,
  Icon,
  Select,
  TextInput,
  Button,
} from "react-materialize";
import { useSearchParams } from "react-router-dom";
import M from "materialize-css";
import { Tooltip } from "react-tooltip";
import SearchUsers from "../animations/SearchUsers";
import useConfirm from "../context/ConfirmDialog";
import { AuthContext } from "../context";

const Community = () => {
  const [usersData, setUsersData] = useState({});
  const [users, setUsers] = useState([]);
  const [usersAround, setUsersAround] = useState([]);
  const [usersAroundData, setUsersAroundData] = useState({});
  const [usersAroundErrorText, setUsersAroundErrorText] = useState(null);
  const [usersPage, setUsersPage] = useState(1);
  const [pageError, setPageError] = useState(null);
  const searchParamsArray = useSearchParamsArray();
  const lastUsersElement = useRef();

  const [cityTitle, setCityTitle] = useState("Город");
  const [locationOptions, setLocationOptions] = useState({ тест: null });
  const [interestOptions, setInterestOptions] = useState(null);
  const [interestIds, setInterestIds] = useState([""]);
  const [usersLocation, setUsersLocation] = useState("");
  const [usersName, setUsersName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectState, setSelectState] = useState(null);
  const [interests, setInterests] = useState([]);
  const [isActive, setIsActive] = useState(0);
  const confirm = useConfirm();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [usersAroundTimeLeft, setUsersAroundTimeLeft] = useState(null);

  useEffect(() => {
    getUsersAroundStatus();
    fetchUsersAround();
  }, []);

  // const getUsers = async () => {
  //   try {
  //     let response = (await RequestService.getUsers()).data; //
  //     console.log(response);
  //     if (!response.response_code) {
  //       console.error(response);
  //       return;
  //     }
  //     setPageError(null);
  //     setUsersData(response.result);
  //   } catch (error) {
  //     // console.log(error);
  //     setPageError(error);
  //   }
  // };

  const getUsers = async (page = 1, fromScratch = false) => {
    try {
      if (!fromScratch && usersData && page > usersData.total_pages) return;
      const filterParams = searchParamsArray.array;
      const urlSearchParams = new URLSearchParams();
      filterParams.forEach((filterParam) => {
        urlSearchParams.append(filterParam[0], filterParam[1]);
      });

      // console.log(urlSearchParams.toString());

      let response = (
        await RequestService.getUsers(null, page, urlSearchParams.toString())
      ).data; //
      // console.log(response);
      if (!response.response_code) {
        console.error(response);
        setPageError(response.message);
        return;
      }
      setPageError(null);
      setUsersData(response.result);

      let newUsers = fromScratch
        ? [...response.result.elements]
        : [...users, ...response.result.elements];
      setUsers(newUsers);
    } catch (error) {
      // console.log(error);
      setPageError(error);
    }
  };

  const getUsersAround = async (page = 1, fromScratch = false) => {
    try {
      if (!fromScratch && usersAroundData && page > usersAroundData.total_pages)
        return;

      let response = (await RequestService.getUsersAround()).data;
      // console.log(response);
      if (!response.response_code) {
        // console.error(response);
        setUsersAroundErrorText(response.message);
        return;
      }
      // setUsersAroundData(response.result);
      setUsersAroundErrorText(null);
      setUsersAround(response.result);
    } catch (error) {
      // console.log(error);
    }
  };

  const getUsersAroundStatus = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.getUsersAroundStatus()).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    setUsersAroundTimeLeft(response.result);
  };

  useEffect(() => {
    // console.log(users);
  }, [users]);

  const setFrontFilters = async () => {
    let interestsArray = await getInterests();
    // console.log(interestsArray);

    const filterParams = searchParamsArray.array;
    const urlSearchParams = new URLSearchParams();
    let tempInterestsIds = [];
    filterParams.forEach((filterParam) => {
      switch (filterParam[0]) {
        case "name":
          setUsersName(filterParam[1]);
          break;

        case "location":
          setUsersLocation(filterParam[1]);
          break;

        case "interests[]":
          // interestsArray.forEach((interest) => {
          // if (interest.name.toLowerCase() == filterParam[1].toLowerCase()) {
          // tempInterestsIds.push(interest.id);
          tempInterestsIds.push(filterParam[1]);
          // }
          // });
          break;

        case "active":
          setIsActive(filterParam[1]);
          break;

        default:
          break;
      }
    });

    // console.log(tempInterestsIds);
    setInterestIds(tempInterestsIds);
    // console.log(filterParams);
  };

  useEffect(() => {
    // console.log(interestIds);
    setSelectState(Math.random());
  }, [interestIds]);

  const [fetchUsers, isUsersLoading, usersError] = useFetching(getUsers);
  const [fetchUsersAround, isUsersAroundLoading, usersAroundError] =
    useFetching(getUsersAround);

  useEffect(() => {
    fetchUsers(1, true);
    setFrontFilters();
  }, [searchParams]);

  const onInputCity = async (e) => {
    // console.log(e.target.value);
    setUsersLocation(e.target.value);
    setCityTitle("Поиск...");
    const response = (await RequestService.searchLocation(e.target.value)).data;
    // console.log(response);
    setCityTitle("Город проведения");
    if (!response.response_code) {
      return;
    }

    let list = {};
    if (response.result.length > 0) {
      response.result.forEach((place) => {
        list[
          place.name_ru // + " (" + place.region_ru + ", " + place.country_ru + ")"
        ] = null;
      });
    }
    setLocationOptions(list);
    // console.log(list);
  };

  const getInterests = async (e) => {
    const interestsResponse = (await RequestService.getInterests()).data;
    // console.log(interestsResponse);
    if (!interestsResponse.response_code) {
      return;
    }
    // console.log(interestsResponse.result);
    let interestOptions = [];
    interestOptions.push(
      <option disabled value="" key="0">
        Выберите один или несколько
      </option>
    );
    interestsResponse.result.forEach((interest) => {
      interestOptions.push(
        <option value={interest.id} key={interest.id}>
          {interest.name}
        </option>
      );
    });
    setInterests(interestsResponse.result);
    setInterestOptions(interestOptions);

    return interestsResponse.result;
  };

  useEffect(() => {
    if (document.getElementById("filter-location")) {
      let cityInputInstance = M.Autocomplete.getInstance(
        document.getElementById("filter-location")
      );

      setTimeout(() => {
        cityInputInstance.open();
      }, 300);
    }
  }, [locationOptions]);

  useEffect(() => {
    // ! magic conds
    if (usersPage == 1) {
      fetchUsers(usersPage, true);
    } else {
      fetchUsers(usersPage);
    }
  }, [usersPage]);

  useObserver(
    lastUsersElement,
    usersData && usersPage < usersData.total_pages,
    isUsersLoading,
    () => {
      setUsersPage(usersPage + 1);
    }
  );

  const searchMeetings = async () => {
    const choice = await confirm({
      description:
        "Вы уверены, что хотите записать свою геопозицию и включить поиск встреч (срок поиска — 1 час)? Мы никому не покажем ваше местоположение — оно необходимо, чтобы найти интересного собеседника недалеко от вас.",
    });
    if (choice) {
      setFullScreenLoading(true);
      let coords = await RequestService.getGeolocation();
      const response = (
        await RequestService.enableMeetingsSearch(coords[0], coords[1])
      ).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description:
          "Поиск встречи запущен, через час он отключится автоматически.",
        isAlert: true,
      });

      getUsersAroundStatus();
      fetchUsersAround();
    }
  };

  return (
    <div>
      <RegularPage
        error={pageError}
        isDataExist={usersData}
        hasBaseCard={false}
        header={"Люди"}
        isPrimaryPage={true}
      >
        <div className="row">
          <div className="col s12">
            <div className="card">
              <div
                className="card-content"
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                <div className="row nm">
                  <div className="col s12 l3 center-align">
                    <SearchUsers />
                  </div>
                  <div className="col s12 l9">
                    <div className="row nm">
                      <div
                        className="col s12 l500 hide-on-med-and-down"
                        style={{ paddingTop: "30px" }}
                      >
                        Не обедаете в одиночку? Или просто ищете интересных
                        людей?
                      </div>
                      <div
                        className="col s12 l500 hide-on-large-only"
                        style={{ marginTop: "-15px" }}
                      >
                        Не обедаете в одиночку? Или просто ищете интересных
                        людей?
                      </div>
                      <div className="col s12 secondary-color mt15">
                        Вы можете включить поиск собеседника рядом. Если мы
                        кого-то найдем, то отобразим его ниже, чтобы вы могли
                        встретиться в самое ближайшее время. Для поиска мы
                        сохраним вашу геопозицию на 1 час. При этом мы никому ее
                        не покажем — ваша конфиденциальность превыше всего.
                      </div>
                      <div className="col s12 mt20 mb30">
                        {usersAroundTimeLeft === null ? (
                          <CircleLoader />
                        ) : usersAroundTimeLeft == 0 ? (
                          <Button
                            flat
                            className="iq-btn"
                            onClick={searchMeetings}
                          >
                            Включить поиск встречи (beta)
                          </Button>
                        ) : (
                          <span className="success-color">
                            Поиск активен еще {usersAroundTimeLeft} мин.{" "}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <div className="card">
              <div className="card-content">
                <div className="row">
                  <div className="col s12 l500">Пользователи рядом</div>
                </div>
                {!isUsersAroundLoading ? (
                  <UserSmallCardList
                    users={usersAround}
                    isPastel={true}
                    error={usersAroundErrorText}
                  />
                ) : null}

                {isUsersAroundLoading ? <CircleLoader /> : null}
              </div>
            </div>
          </div>
        </div>

        <Filter
          title={"Поиск пользователей"}
          classes={"mb25"}
          resetPage={() => {
            setUsersPage(1);
          }}
          resetForm={() => {
            setUsersLocation("");
            setUsersName("");
            setInterestIds([]);
            setIsActive(0);
          }}
        >
          <TextInput
            id="filter-name"
            name="filter-name"
            s={10}
            l={6}
            label="Имя"
            placeholder="Сергей Иванов"
            value={usersName}
            onChange={(e) => {
              setUsersName(e.target.value);
            }}
            type="text"
          />
          <div
            className="col l1 s2"
            onClick={() => {
              document.querySelector("#filter-name").value = "";
              setUsersName("");
            }}
          >
            <Icon
              style={{
                position: "absolute",
                marginTop: "25px",
                cursor: "pointer",
              }}
            >
              cancel
            </Icon>
          </div>

          <Autocomplete
            id="filter-location"
            name="filter-location"
            options={{
              data: locationOptions,
              onAutocomplete: (location) => {
                setUsersLocation(location);
              },
            }}
            title={cityTitle}
            label={"Город"}
            placeholder={"Екатеринбург"}
            s={10}
            l={4}
            onChange={onInputCity}
            value={usersLocation}
            onBlur={() => {
              // setGeoInputStates();
            }}
          />

          <div
            className="col l1 s2"
            onClick={() => {
              document.querySelector("#filter-location").value = "";
              setUsersLocation("");
            }}
          >
            <Icon
              style={{
                position: "absolute",
                marginTop: "25px",
                cursor: "pointer",
              }}
            >
              cancel
            </Icon>
          </div>

          <Select
            id="filter-interests"
            name="filter-interests"
            s={12}
            l={5}
            label={"Сфера интересов"}
            multiple
            value={interestIds}
            key={selectState}
            data-type={"array"}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                // autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                // coverTrigger: true,
                // hover: false,
                // inDuration: 150,
                // onCloseEnd: null,
                // onCloseStart: null,
                // onOpenEnd: null,
                // onOpenStart: null,
                // outDuration: 250,
              },
            }}
            // value={[""]}
          >
            {interestOptions}
          </Select>
          <div className="col s12 l3 mt20 mb20">
            <div className="switch">
              <label style={{ fontSize: "100%" }}>
                <input
                  checked={isActive == 1 ? "checked" : ""}
                  type="checkbox"
                  data-type={"checkbox"}
                  id="filter-active"
                  name="filter-active"
                  onChange={(e) => {
                    e.target.checked ? setIsActive(1) : setIsActive(0);
                  }}
                />
                <span className="lever" style={{ margiLeft: "0px" }}></span>
                <span className="tertiary-color">Только активные</span>
                <i
                  className="material-icons tertiary-color"
                  style={{
                    cursor: "pointer",
                    marginLeft: "8px",
                    // marginTop: "1px",
                    position: "absolute",
                  }}
                  data-tooltip-id={"active-filter-tooltip"}
                  data-tooltip-html="Только пользователи, которые<br>заходили в последние 30 дней"
                >
                  help_outline
                </i>
                <Tooltip
                  id={"active-filter-tooltip"}
                  style={{
                    fontSize: "13px",
                    backgroundColor: "var(--accent-color)",
                    zIndex: "999",
                  }}
                  place={"top"}
                />
              </label>
            </div>
          </div>
        </Filter>
        <UserSmallCardList users={users} />
        {isUsersLoading ? <CircleLoader /> : null}
      </RegularPage>
      <div
        ref={lastUsersElement}
        // style={{ backgroundColor: "red", height: "10px" }}
      ></div>
    </div>
  );
};

export default Community;
