import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { Button, Icon, TextInput } from "react-materialize";
import {
  useParams,
  useSearchParams,
  Link,
  useNavigate,
} from "react-router-dom";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { formatNumber } from "../utils/service";
import noImage from "../images/no-image.png";
import { AuthContext } from "../context";
import useConfirm from "../context/ConfirmDialog";

const GetPackage = () => {
  const params = useParams();
  const [packageError, setPackageError] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [code, setCode] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [codeClasses, setCodeClasses] = useState("hide");
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    setPayScreen();
  }, []);

  const setPayScreen = async () => {
    let username = searchParams.get("user");
    let packageId = params.id;

    if (!packageId) {
      setPackageError("В заказе не обнаружен идентификатор пакета участия");
      return;
    }
    await getPackage(packageId);
    if (username) {
      await getUser(username);
    }
  };

  const getPackage = async (packageId) => {
    let response = (await RequestService.getPackage(packageId)).data;
    // console.log(response);
    if (!response.response_code) {
      setPackageError(response.message);
      return;
    }
    if (response.result.currency_id != 1) {
      setPackageError("Оплата пакета участия недоступна");
      return;
    }
    if (response.result.currency_id == 1 && response.result.price > 0) {
      setPackageError("Оплата пакета участия недоступна #2");
      return;
    }
    if (response.result.object_type == 3) {
      setPackageError("Оплата пакета участия недоступна #3");
      return;
    }

    setPackageData(response.result);
  };

  const getUser = async (username) => {
    const response = (await RequestService.getUser(username)).data;
    // console.log(response);
    if (!response.response_code) {
      setPackageError(response.message);
      return;
    }
    setUserData(response.result);
  };

  const join = async (e) => {
    // e.preventDefault();
    setFullScreenLoading(true);
    let response = (
      await RequestService.requestFreeTicket(params.id, customerEmail, code)
    ).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (response.response_code == 1) {
      if (customerEmail && !code) {
        setCodeClasses("");
      } else {
        navigate("/thanks");
      }
    } else {
      await confirm({
        description: response.message,
        isAlert: true,
      });

      setCode("");
    }
  };

  return (
    <RegularPage
      error={packageError}
      isDataExist={
        searchParams.get("user") ? packageData && userData : packageData
      }
      header={"Бесплатное участие"}
      backText={"Назад"}
    >
      {(searchParams.get("user") && packageData && userData) ||
      (!searchParams.get("user") && packageData) ? (
        <div>
          <div className="row mt25 secondary-color">
            <div className="col s12">
              Пожалуйста, внимательно проверьте детали перед записью. После
              записи организатор получит соответствующую информацию. Для
              посещения мероприятия вам понадобится электронный билет, который
              будет храниться в разделе «Мои участия».
            </div>
          </div>
          <div className="row mt35">
            <div className="col s12 l6 mb10 center-align">
              <img
                src={
                  packageData.object_cover_img_url
                    ? packageData.object_cover_img_url
                    : noImage
                }
                className={
                  !packageData.object_cover_img_url
                    ? "image-theme-filter"
                    : null
                }
                alt="Изображение события"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  borderRadius: "15px",
                }}
              />
            </div>
            <div className="col s12 l6">
              <span className="m500">Событие/проект:</span>{" "}
              <Link to={"/events/" + packageData.parent_id} className="iq-link">
                «{packageData.object_name}»
              </Link>
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Пакет участия:</span> «{packageData.name}»
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Цена:</span>{" "}
              {formatNumber(packageData.price)} {packageData.currency_symbol}
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Формат участия:</span>{" "}
              {packageData.object_format == 1
                ? "Офлайн"
                : packageData.object_format == 2
                ? "Онлайн"
                : "Смешанный (онлайн + офлайн)"}
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Дата и время:</span>{" "}
              {moment(packageData.object_datetime).format("DD.MM.YYYY HH:mm") +
                " (" +
                (packageData.object_timezone_description
                  ? packageData.object_timezone_description
                  : "время местное") +
                ")"}
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Место:</span>{" "}
              {packageData.object_location +
                "; " +
                packageData.object_address +
                "; " +
                packageData.object_place}
            </div>
            {userData ? (
              <div className="col s12 l6 mt10">
                <span className="m500">Участник:</span>{" "}
                {userData.first_name +
                  " " +
                  userData.last_name +
                  " (@" +
                  userData.alias +
                  ")"}
              </div>
            ) : null}

            <div>
              <div className="col s12 l500 mt25">
                Укажите email, на который вы оформляете участие
              </div>
              <div className="col s12 secondary-color mt10">
                Мы автоматически создадим вам аккаунт Nott.one, куда добавим
                вашу покупку в раздел «Мои участия».
              </div>
              <div className="col s12">
                <div className="row nm">
                  <div className="col l3"></div>
                  <TextInput
                    type="email"
                    placeholder="youremail@example.com"
                    l={6}
                    s={12}
                    icon={<Icon>alternate_email</Icon>}
                    onChange={(e) => {
                      setCustomerEmail(e.target.value);
                    }}
                    value={customerEmail}
                  />
                  <div className="col l3"></div>
                </div>

                <div className={"row nm " + codeClasses}>
                  <div className="col s12 center-align success-color">
                    Отправили вам код подтверждения на email. У вас одна попытка
                    ввода кода.
                  </div>
                  <div className="col l3"></div>

                  <TextInput
                    type="text"
                    label="Код подтверждения"
                    l={6}
                    s={12}
                    icon={<Icon>key</Icon>}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    value={code}
                  />

                  <div className="col l3"></div>
                </div>
              </div>
            </div>
            <div className="col s12 center-align mt15">
              <Button flat className="iq-btn" onClick={join}>
                Участвовать
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </RegularPage>
  );
};

export default GetPackage;
