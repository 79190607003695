import React from "react";

const OnlineIndicator = ({
  mt = 0,
  ml = 0,
  themeColor = false,
  isOnline = false,
  size = "s",
}) => {
  let randomId = (Math.random() * 100000).toFixed(0);

  return isOnline ? (
    <div
      style={{
        backgroundColor: themeColor
          ? "var(--bg-color)"
          : "var(--surface-bg-color)",
        position: "absolute",
        marginTop: mt,
        marginLeft: ml,
        width: size == "s" ? "15px" : "20px",
        height: size == "s" ? "15px" : "20px",
        borderRadius: "100%",
      }}
    >
      <div
        style={{
          marginLeft: "2.5px",
          marginTop: "2.5px",
          width: size == "s" ? "10px" : "15px",
          height: size == "s" ? "10px" : "15px",
          backgroundColor: "var(--success-color)",
          borderRadius: "100%",
          // position: "absolute",
        }}
      ></div>
    </div>
  ) : null;
};

export default OnlineIndicator;
