import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "../pages/Login";
import { privateRoutes, publicRoutes } from "../router";
import { AuthContext } from "../context";
import CircleLoader from "./UI/CircleLoader/CircleLoader";
import Navbar from "./UI/Navbar/Navbar";
import ErrorMessage from "./UI/ErrorMessage/ErrorMessage";
import Event from "../pages/Event";

const AppRouter = () => {
  const { isAuth, isLoading, globalError } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) {
    // console.info('Работает индикатор загрузки');
    return <CircleLoader status={isLoading} />;
  }

  if (globalError) {
    return <ErrorMessage error={globalError} />;
  }

  // console.log(new URL(window.location));
  // console.log(location);
  // console.log(isAuth, isLoading);
  // console.log(window.location);

  return isAuth ? (
    <Routes>
      {privateRoutes.map((route) => (
        <Route element={route.element} path={route.path} key={route.path} />
      ))}
      <Route path="/*" element={<Navigate to="/events" replace />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route element={route.element} path={route.path} key={route.path} />
      ))}
      <Route
        path="/*"
        element={
          <Navigate
            to={
              "/login?redirect_uri=" +
              encodeURIComponent(
                window.location.origin + location.pathname + location.search
              ) // window.location.href
            }
            replace
          />
        }
      />
    </Routes>
  );
};

export default AppRouter;
