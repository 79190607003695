import React from "react";
import { Link } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";

const Thanks = () => {
  return (
    <RegularPage isDataExist={1}>
      <div className="row">
        <div className="col s12">
          <div className="card">
            <div className="card-content center-align">
              <div className="row">
                <div className="col s12">
                  <i className="material-icons success-color large">task_alt</i>
                </div>
              </div>
              <div className="row">
                <div className="col s12 xxl500 center-align">Спасибо!</div>
              </div>
              <div className="row nm secondary-color">
                <div className="col s12">
                  Вы успешно оформили заказ. Ваш электронный билет доступен в
                  разделе{" "}
                  <Link to="/tickets" className="iq-link">
                    Мои участия
                  </Link>
                  . Если у вас возникнут проблемы технического характера,
                  сообщите нам на почту{" "}
                  <a href="mailto:support@nott.one" className="iq-link">
                    support@nott.one
                  </a>
                  . В письме укажите{" "}
                  <span className="m500">имя пользователя/email</span>, на
                  которого был оформлен заказ,{" "}
                  <span className="m500">сумму заказа</span> и{" "}
                  <span className="m500">подробное описание</span> проблемы.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RegularPage>
  );
};

export default Thanks;
