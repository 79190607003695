import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import RequestService from "../api/RequestService";
import { useContext } from "react";
import { AuthContext } from "../context";
import noAvatar from "../images/no-avatar.png";
import moment from "moment";
import { Modal, Button, Icon } from "react-materialize";
import NewComplaint from "../components/UI/Complaints/NewComplaint/NewComplaint";
import { copyTextToClipboard } from "../utils/service";
import Wall from "../components/UI/Wall/Wall";
import EventTiles from "../components/UI/Event/EventTiles";
import UserSmallCardList from "../components/UI/Users/UserSmallCard/UserSmallCardList";
import UserSmallCard from "../components/UI/Users/UserSmallCard/UserSmallCard";

const Feed = () => {
  const params = useParams();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [events, setEvents] = useState({});
  const [users, setUsers] = useState([]);
  //   const [postData, setPostData] = useState(null);
  //   const [postError, setPostError] = useState(null);

  const getEvents = async (coords = null) => {
    // if (events && page > events.total_pages) return;
    let response = (await RequestService.getAllEvents(coords)).data;
    if (!response.response_code) {
      console.error(response);
      return;
    }
    let allEventsData = response.result;
    // console.log(allEventsData);
    setEvents(allEventsData);
  };

  useEffect(() => {
    // console.log(eventPage);
    setEventsPage();
    getUsers();
  }, []);

  const getGeolocation = async () => {
    let response = await RequestService.getGeolocation();
    // console.log(response);
    // response = [56.817796, 60.580228];
    if (!response) return;
    return response;
  };

  const setEventsPage = async () => {
    const coords = await getGeolocation();
    getEvents(coords);
  };

  const getUsers = async (page = 1) => {
    try {
      let response = (await RequestService.getUsers(null, page, "active=1"))
        .data; //
      // console.log(response);
      if (!response.response_code) {
        console.error(response);
        return;
      }

      setUsers(response.result.elements);
    } catch (error) {
      console.log(error);
      // setPageError(error);
    }
  };

  return (
    <RegularPage
      //   error={postError}
      isDataExist={1}
      header={"Лента"}
      isPrimaryPage={true}
      hasBaseCard={true}
      // backText={"Все публикации"}
      // backPath={"/posts"}
    >
      <div className="row">
        <div className="col s12 xl500">Интересные люди</div>
        <div className="col s12 mt10">Листайте →</div>
        <div className="col s12">
          или{" "}
          <Link className="iq-link" to={"/users"}>
            посмотрите всех
          </Link>
        </div>
      </div>
      <div className="horizontal-scrolling-wrapper">
        <UserSmallCardList
          users={users}
          isPastel={true}
          onlyCommonCol={true}
          disableOnlineIndicator={true}
        />
      </div>
      <div className="row mt25">
        <div className="col s12 xl500">События рядом</div>
        <div className="col s12 mt10">Листайте →</div>
        <div className="col s12">
          или{" "}
          <Link className="iq-link" to={"/events"}>
            откройте карту
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="horizontal-scrolling-wrapper">
          <EventTiles
            events={events}
            isLoading={false}
            onSurface={true}
            hideOldAndNoDatetime={true}
            onlyCommonCol={true}
            withInterests={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col s12 xl500">Новости</div>
      </div>
      <Wall isCurrentUser={0} allPosts={true} />
    </RegularPage>
  );
};

export default Feed;
