import React, { useEffect } from "react";
import useConfirm from "../../../context/ConfirmDialog";

const LinkObserver = () => {
  const confirm = useConfirm();

  useEffect(() => {
    linkTracking();
  }, []);

  const linkTracking = async () => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === 1 && node.tagName === "A") {
              //   console.log("Добавлена новая ссылка:", node.href);

              node.removeEventListener("click", onLink);
              node.addEventListener("click", onLink);
            }

            if (node.nodeType === 1) {
              node.querySelectorAll("a").forEach((link) => {
                // console.log("Добавлена новая вложенная ссылка:", link.href);

                link.removeEventListener("click", onLink);
                link.addEventListener("click", onLink);
              });
            }
          });
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    async function onLink(e) {
      let href = e.target.getAttribute("href");
      if (e.target.getAttribute("rel")) {
        e.preventDefault();
        let choice = await confirm({
          description:
            "Вы открываете внешнюю ссылку " +
            href +
            ". Nott.one не несет ответственности за содержимое ссылки. Вы уверены, что хотите продолжить?",
        });

        if (choice) {
          window.open(href, "_blank").focus();
        }
      }
    }
  };

  return null;
};

export default LinkObserver;
