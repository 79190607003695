import "./styles/App.css";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { AuthContext } from "./context";
import { useState, useEffect } from "react";
import {
  getCookie,
  SERVER_SITE,
  SUPPORT_EMAIL,
  VERSION,
  VKID_AUTH_REDIRECT_URL,
  VKID_REDIRECT_URL,
  WEB_SITE,
  WS,
} from "./utils/service";
import Navbar from "./components/UI/Navbar/Navbar";
import RequestService from "./api/RequestService";
import FullScreenLoader from "./components/UI/FullScreenLoader/FullScreenLoader";
import { ConfirmDialogProvider } from "./context/ConfirmDialog";
import LinkObserver from "./components/UI/LinkObserver/LinkObserver";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isFullScreenLoading, setFullScreenLoading] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const [ws, setWs] = useState(null);
  const [contentUpdateState, setContentUpdateState] = useState(null);

  window.nott = {
    info: {
      support_email: SUPPORT_EMAIL,
      server_site: SERVER_SITE,
      web_site: WEB_SITE,
      version: VERSION,
      vkid_redirect_url: VKID_REDIRECT_URL,
      vkid_auth_redirect_url: VKID_AUTH_REDIRECT_URL,
      ws: WS,
    },
  };

  useEffect(() => {
    // console.info("запуск проверки");
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      // console.info("начинаем загрузку");
      setLoading(true);
      if (getCookie("nott_xsrf_token")) {
        setIsAuth(true);
        setLoading(false);
        // console.info("прекращаем загрузку");
        return;
      }

      await RequestService.refreshTokens();
      if (getCookie("nott_xsrf_token")) {
        setIsAuth(true);
        setLoading(false);
        return;
      }

      setIsAuth(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setGlobalError(error);
      // console.log(error);
    }
  };

  // console.info(window.nott);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        isLoading,
        setLoading,
        isFullScreenLoading,
        setFullScreenLoading,
        globalError,
        setGlobalError,
        ws,
        setWs,
        contentUpdateState,
        setContentUpdateState,
      }}
    >
      <ConfirmDialogProvider>
        <BrowserRouter>
          <LinkObserver />
          <Navbar />
          {/* {isAuth ? <Navbar /> : null} */}
          <FullScreenLoader state={isFullScreenLoading} />
          <AppRouter />
        </BrowserRouter>
      </ConfirmDialogProvider>
    </AuthContext.Provider>
  );
}

export default App;
