import React from "react";
import { useNavigate } from "react-router-dom";
import noImage from "../../../images/no-avatar.png";
import moment from "moment";
import { getLocalTime, truncate } from "../../../utils/service";
import Verified from "../Verified/Verified";
import InfoLabel from "../InfoLabel/InfoLabel";
import OnlineIndicator from "../indicators/OnlineIndicator";
// import { truncate } from "../../../../utils/service";

const ChatItem = ({ chat, onSurface }) => {
  // console.log(chat);
  const navigate = useNavigate();

  return (
    <div className="row nm">
      <div className="col s12">
        <div
          className={
            onSurface
              ? "card mb30 hand-cursor bg animation-appear"
              : "card mb30 hand-cursor animation-appear"
          }
          onClick={() => {
            navigate(
              chat.id.length == 32
                ? "/packages/" + chat.id + "/chat"
                : "/messages/" + chat.id
            );
          }}
        >
          <div className="card-content">
            <div
              className="row nm"
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="col s4 l2">
                <div className="row nm">
                  <div className="col s12">
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "70px",
                        height: "70px",
                        position: "relative",
                        margin: "auto",
                      }}
                    >
                      <div
                        className={
                          chat.avatar_img_url
                            ? "circular-image"
                            : "circular-image image-theme-filter"
                        }
                        style={{
                          backgroundImage:
                            "url(" +
                            (chat.avatar_img_url
                              ? chat.avatar_img_url
                              : noImage) +
                            ")",
                          height: "70px",
                          width: "70px",
                        }}
                      ></div>

                      {chat.id.length == 32 ? (
                        <InfoLabel icon={"groups"} />
                      ) : null}

                      <OnlineIndicator
                        ml={53}
                        mt={-15}
                        isOnline={chat.user_is_online}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col s8 l10">
                <div className="row nm">
                  <div className="col s7 l10 m500 mb10">
                    {chat.user_name}
                    <Verified
                      ml={5}
                      mt={-3}
                      isOriginal={chat.user_is_original}
                      isVerified={chat.user_is_verified}
                    />
                  </div>
                  <div className="col s5 l2 s300 right-align">
                    {moment().format("DD.MM.YYYY") ==
                    getLocalTime(chat.timestamp).date
                      ? getLocalTime(chat.timestamp).hm
                      : getLocalTime(chat.timestamp).datetime}
                  </div>
                  <div className="col s9">{truncate(chat.text, 40)}</div>
                  <div className="col s3 m500 mb10 right-align">
                    {chat.new_messages ? (
                      <span className="accent-counter">
                        {chat.new_messages}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
