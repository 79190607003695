import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import noAvatar from "../../../images/no-avatar.png";
import { getLocalTime } from "../../../utils/service";

const Message = ({
  isOwner,
  name,
  text,
  datetime,
  userAlias = null,
  isPackageMessage = 0,
  isModerator = null,
  isOrganizer = null,
  avatarUrl = null,
  fullWidthMessage = false,
}) => {
  return (
    <div>
      {isOwner ? (
        <div className="row mt10 mb10">
          {!fullWidthMessage ? <div className="col l4"></div> : null}

          <div
            className={
              !fullWidthMessage
                ? "col s12 l8 card accent-color-bg primary-color"
                : "col s12 card accent-color-bg primary-color"
            }
          >
            <div className="card-content" style={{ padding: "15px" }}>
              <div className="row nm">
                {/* <div className="col s3 l2">
                  <div
                    className="circular-image pastel-shadow"
                    style={{
                      width: "45px",
                      height: "45px",
                    }}
                  >
                    <div
                      className={
                        avatarUrl
                          ? "circular-image"
                          : "circular-image image-theme-filter"
                      }
                      style={{
                        backgroundImage:
                          "url(" + (avatarUrl ? avatarUrl : noAvatar) + ")",
                        height: "45px",
                        width: "45px",
                      }}
                    ></div>
                  </div>
                </div> */}
                <div className="col s12">
                  <div className="m700 mb10">
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "22px",
                        height: "22px",
                        position: "absolute",
                      }}
                    >
                      <div
                        className={
                          avatarUrl
                            ? "circular-image"
                            : "circular-image image-theme-filter"
                        }
                        style={{
                          backgroundImage:
                            "url(" + (avatarUrl ? avatarUrl : noAvatar) + ")",
                          height: "22px",
                          width: "22px",
                        }}
                      ></div>
                    </div>
                    <span
                      style={{
                        marginLeft: "35px",
                      }}
                    >
                      {userAlias ? (
                        <Link
                          className="primary-color"
                          to={"/users/" + userAlias}
                        >
                          {name +
                            (isOrganizer
                              ? " (организатор)"
                              : isModerator
                              ? " (модератор)"
                              : "")}
                        </Link>
                      ) : (
                        name +
                        (isOrganizer
                          ? " (организатор)"
                          : isModerator
                          ? " (модератор)"
                          : "")
                      )}
                    </span>
                  </div>
                  <div>{text}</div>
                  <div className="right s300 mt10">
                    {getLocalTime(datetime, "DD.MM.YYYY HH:mm").datetime}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mt10 mb10">
          <div
            className={
              !fullWidthMessage
                ? "col s12 l8 card secondary-color"
                : "col s12 card secondary-color"
            }
          >
            <div className="card-content" style={{ padding: "15px" }}>
              <div className="row nm">
                <div className="col s12">
                  <div className="m700 mb10">
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "22px",
                        height: "22px",
                        position: "absolute",
                      }}
                    >
                      <div
                        className={
                          avatarUrl
                            ? "circular-image"
                            : "circular-image image-theme-filter"
                        }
                        style={{
                          backgroundImage:
                            "url(" + (avatarUrl ? avatarUrl : noAvatar) + ")",
                          height: "22px",
                          width: "22px",
                        }}
                      ></div>
                    </div>

                    <span
                      style={{
                        marginLeft: "35px",
                      }}
                    >
                      {userAlias ? (
                        <Link
                          className="secondary-color"
                          to={"/users/" + userAlias}
                        >
                          {name +
                            (isOrganizer
                              ? " (организатор)"
                              : isModerator
                              ? " (модератор)"
                              : "")}
                        </Link>
                      ) : (
                        name +
                        (isOrganizer
                          ? " (организатор)"
                          : isModerator
                          ? " (модератор)"
                          : "")
                      )}
                    </span>
                  </div>
                  <div>{text}</div>
                  <div className="right s300 mt10">
                    {getLocalTime(datetime, "DD.MM.YYYY HH:mm").datetime}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!fullWidthMessage ? <div className="col l4"></div> : null}
        </div>
      )}
    </div>
  );
};

export default Message;
